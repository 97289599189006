import "./AdminSuccessPage.css"

const AdminSuccessForm = () => {
    return (
        <div className={"formCard"}>
            <h2>Congratulations, your gift exchange has started! </h2>
        </div>
    )
}

export default AdminSuccessForm